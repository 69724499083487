import moment from 'moment';
import { formatCurrency } from 'utilities/common';

export const formatUSCurrency = (value) => {
  try {
    if (typeof value !== 'number') {
      return null;
    }
    return formatCurrency(value);
  } catch (_) {
    return null;
  }
};

export const durationInMonths = (date) => {
  if (!date) {
    return null;
  }
  const now = new Date();
  const start = new Date(date);
  return (
    now.getMonth() -
    start.getMonth() +
    12 * (now.getFullYear() - start.getFullYear())
  );
};

export const formatShortDate = (date) => {
  if (!date) {
    return null;
  }

  const aDate = new Date(date);
  if (!(aDate instanceof Date) || Number.isNaN(aDate)) {
    return null;
  }

  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return aDate.toLocaleDateString('en-us', options);
};

export const isLeased = (vehicle) => {
  return vehicle?.ownershipTypeCode === 'GF';
};

export const compareDisposalAcquisitionAndTodayDates = (
  disposalDate,
  acquisitionDate,
  field,
) => {
  let errorMessages = '';
  const isDisposalLessThanAcquisitionDate =
    moment(disposalDate).diff(moment(acquisitionDate)) <= 0;
  const isAcquisitionDateMoreThanYesterday =
    moment(acquisitionDate).diff(moment(new Date())) > 0;

  if (field === 'disposalDate' && isDisposalLessThanAcquisitionDate) {
    errorMessages =
      'Disposal date must be greater than the acquisition date (when provided)';
  }

  if (
    field === 'acquisitionDate' &&
    (isDisposalLessThanAcquisitionDate || isAcquisitionDateMoreThanYesterday)
  ) {
    errorMessages =
      "Acquisition date must be on or before today's date and less than disposal date (when provided)";
  }

  return errorMessages;
};

export const getYesOrNo = (data) => {
  let dataValue;
  if (data === true) {
    dataValue = 'Yes';
  } else if (data === false) {
    dataValue = 'No';
  }
  return dataValue;
};

export const getMoneyFormat = (value) => {
  if (value)
    return `$${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  return null;
};

export const getThousandSeperatedValue = (value) => {
  if (value) return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return '';
};
