import { z } from 'zod';

export const getTerminateSchema = (assignmentMileage) => {
  return z.object({
    terminationDate: z
      .string()
      .nonempty({ message: 'Termination date is required' })
      .nullish(),
    endOdometer: z
      .string()
      .nonempty({ message: 'End mileage is required' })
      .pipe(
        z.coerce
          .number()
          .gt(assignmentMileage, {
            message: 'Mileage must be greater than assignment.',
          }),
      ),
    comment: z.string().nullish(),
  });
};
