import React from 'react';
import { canViewGFVehicleBM, canViewAOVehicles } from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  AOCustomerInfo,
  GFCustomerInfo,
  DisposalInfo,
  Overview,
  PurchaseInfo,
  VehicleDetails,
  VehicleStatus,
} from './components';
import { useVehicle } from '../../vehicle-context-provider';
import './components/styles.css';

const VehicleOverview = () => {
  const { vehicle } = useVehicle();
  const ability = useAppAbility();

  const canView =
    vehicle?.ownershipTypeCode === 'GF'
      ? canViewGFVehicleBM(ability)
      : canViewAOVehicles(ability);

  return (
    <div>
      <div className="grid-container padding-x-0">
        <div className="grid-row margin-bottom-2">
          <div className="tablet:grid-col-12">
            <Overview data={vehicle} />
          </div>
        </div>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-6">
            {vehicle.ownershipTypeCode === 'AO' && (
              <VehicleStatus data={vehicle} />
            )}
            <VehicleDetails data={vehicle} />
          </div>
          <div className="tablet:grid-col-6">
            <div className="tablet:grid-col-12 margin-bottom-4">
              {vehicle?.ownershipTypeCode === 'AO' && (
                <AOCustomerInfo data={vehicle} />
              )}
              {vehicle?.ownershipTypeCode === 'GF' && (
                <GFCustomerInfo data={vehicle} />
              )}
            </div>
            <div className="tablet:grid-col-12">
              <PurchaseInfo data={vehicle} />
              {canView && <DisposalInfo data={vehicle} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleOverview;
