import { z } from 'zod';

const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
export const getAssignVehicleFormSchema = (odometer) => {
  const min = odometer || 0;
  const errorMessage = odometer
    ? 'The mileage is less than current odometer.'
    : 'Mileage must be greater than 0';
  const beginOdometerSchema = z
    .string()
    .nonempty({ message: 'Begin mileage is required' })
    .pipe(z.coerce.number().gt(min, { message: errorMessage }));
  return z
    .object({
      garageCountryCode: z.string().nullish(),
      garageAddress1: z.string().nullish(),
      garageAddress2: z.string().nullish(),
      garageAddress3: z.string().nullish(),
      garageCity: z.string().nullish(),
      garageStateCode: z.string().nullish(),
      garagePostalCode: z
        .string()
        .nonempty({ message: 'Postal code is required' })
        .regex(zipRegExp, 'Enter valid postal code.')
        .nullish(),
      fundCode: z.string().nullish(),
      account1: z.string().nullish(),
      account2: z.string().nullish(),
      assignmentDate: z.string(),
      beginOdometer: beginOdometerSchema,
      endOdometer: z
        .string()
        .nonempty({ message: 'End mileage is required' })
        .pipe(z.coerce.number()),
      numberOfKeys: z.coerce.number(),
      comment: z.string().nullish(),
    })
    .superRefine((val, ctx) => {
      const { beginOdometer, endOdometer, assignmentDate } = val;
      if (!assignmentDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          path: ['assignmentDate'],
          fatal: true,
          message: 'Assignment date is required',
        });
      }

      if (beginOdometer && endOdometer) {
        if (endOdometer && endOdometer < beginOdometer) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_arguments,
            message: 'Begin mileage must be less than end mileage',
            fatal: true,
            path: ['beginOdometer'],
          });
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_arguments,
            message: 'End mileage must be greater than begin mileage',
            fatal: true,
            path: ['endOdometer'],
          });
        }
      }
    });
};
