import { useMutation } from '@apollo/client';
import { Button, DetailsTable, Spinner } from '@gsa/afp-component-library';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getCodeAndNameByOwnerShip } from 'utilities/format';
import { UPDATE_VEHICLE_AGENCY_DETAILS } from 'services/data-layer';
import { VehiclePropType } from 'utilities/types';
import useUser from 'utilities/use-user';
import useCanPerformActions from '../../../../../hooks/use-can-perform-actions';
import AgencyEdit from '../../registration/agency-edit';
import {
  getOfficeDetails,
  vehicleOwnership,
} from '../helpers/vehicle-details-helper';
import SectionBlock from './section-block';

import { useVehicle } from '../../../vehicle-context-provider';

const AOCustomerInfo = ({ data }) => {
  const { isRole } = useUser();
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const canPerformActions = useCanPerformActions();
  const canEditPoc = canPerformActions.canEditPoc(data, hasFMVRSrole);
  const [editingAgency, setEditingAgency] = useState(false);

  const { setSectionMsg, refetchVehicle } = useVehicle();

  const [updateVehicleAgencyDetails, { loading: updatingVehicleAgency }] =
    useMutation(UPDATE_VEHICLE_AGENCY_DETAILS, {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        refetchVehicle();
        setSectionMsg({
          type: 'success',
          message: 'Vehicle was successfully updated.',
        });
      },
      onError: (err) => {
        setSectionMsg({
          type: 'error',
          message: `Error occurred updating agency information: ${err.message}`,
        });
      },
    });
  const {
    agency,
    ownershipTypeCode,
    customer,
    agencyCode,
    bureau,
    bureauCode,
     officeCode,
  } = data;

  const handleAgencySave = () => {
    setEditingAgency(false); // close modal
    updateVehicleAgencyDetails({
      variables: {
        vin: data.id,
        agencyDetails: {
          agencyCode,
          bureauCode,
          officeCode,
        },
      },
    });
  };

  return (
    <>
      <SectionBlock title="Customer">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={[
            ['Ownership', vehicleOwnership(data.ownershipTypeCode) || '\u2014'],
            [
              'Agency',
              getCodeAndNameByOwnerShip({
                ownershipTypeCode,
                code: agencyCode,
                name: agency?.name,
                customer,
              }),
            ],
            [
              'Bureau',
              getCodeAndNameByOwnerShip({
                ownershipTypeCode,
                code: bureauCode,
                name: bureau?.name,
                customer,
                isAgency: false,
              }),
            ],
            ['Office', getOfficeDetails(data.office, data.officeCode)],
          ]}
        />
        {canEditPoc && (
          <Button
            label="Edit"
            data-testid="agency-edit-button"
            onClick={() => setEditingAgency(true)}
            variant="outline"
            className="bg-white margin-top-2"
            aria-label="edit agency"
          />
        )}
        <AgencyEdit
          agencyCode={data.agencyCode}
          bureauCode={data.bureauCode}
          subSectionCode={data.officeCode}
          editing={editingAgency}
          onClose={() => {
            setEditingAgency(false);
          }}
          onSave={handleAgencySave}
        />
      </SectionBlock>
      {updatingVehicleAgency && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

AOCustomerInfo.propTypes = {
  data: PropTypes.shape(VehiclePropType).isRequired,
};

export default AOCustomerInfo;
