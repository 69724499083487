import React from 'react';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import AgencyFilterItem from 'components/user-filters/custom-filters/agency-filter-item';
import BureauFilterItem from 'components/user-filters/custom-filters/bureau-filter-item';
import OfficeFilterItem from 'components/user-filters/custom-filters/office-filter-item';
import AgencyIndicatorFilterItem from 'components/user-filters/custom-filters/agency-indicator-filter-item';
import FmcFilterItem from 'components/user-filters/custom-filters/fmc-filter-item';
import PmStatusItem from './pm-status-item';
import { flatten } from 'lodash';

export const fsrEmailFilterPath = '$vehicle.fsr_user_email_address$';
export const tagNumberFilterPath = '$vehicle.tag_number$';
export const primaryPocEmailPath =
  '$vehicle.assetContactAssociationPrimary.pointOfContactPrimary.email_address$';
const agencyFilterPath = '$vehicle.customer.customer_agency_code$';
const bureauFilterPath = '$vehicle.customer.customer_bureau_code$';
const officeFilterPath = '$vehicle.customer.customer_office_code$';
const agencyIndicatorFilterPath = '$vehicle.customer.agency_sub_group$';
const pmStatusFilterPath = 'pm_status';
const zoneFilterPath = '$vehicle.customer.zone_id$';
const fmcFilterPath = '$vehicle.fmc_id$';

// eslint-disable-next-line react/prop-types
const PmAgencyFilterItem = ({ filter }) => {
  return (
    <AgencyFilterItem
      filter={filter}
      operation={VMSOperations.VIEW_VEHICLE_GF}
      subject={VMSSubjects.VEHICLE}
    />
  );
};

// eslint-disable-next-line react/prop-types
const PmBureauFilterItem = ({ filter }) => {
  return (
    <BureauFilterItem
      filter={filter}
      operation={VMSOperations.VIEW_VEHICLE_GF}
      subject={VMSSubjects.VEHICLE}
      agencyFilterPath={agencyFilterPath}
      bureauFilterPath={bureauFilterPath}
    />
  );
};

const PmOfficeFilterItem = ({ filter }) => {
  return (
    <OfficeFilterItem
      filter={filter}
      operation={VMSOperations.VIEW_VEHICLE_GF}
      subject={VMSSubjects.VEHICLE}
      agencyFilterPath={agencyFilterPath}
      bureauFilterPath={bureauFilterPath}
      officeFilterPath={officeFilterPath}
    />
  );
};

const PmAgencyIndicatorFilterItem = ({ filter }) => {
  return (
    <AgencyIndicatorFilterItem
      filter={filter}
      agencyFilterPath={agencyFilterPath}
      bureauFilterPath={bureauFilterPath}
      agencyIndicatorFilterPath={agencyIndicatorFilterPath}
    />
  );
};

const PmFmcFilterItem = ({ filter, fmcsWithZoneAndRegion }) => {
  return (
    <FmcFilterItem
      fmcsWithZoneAndRegion={fmcsWithZoneAndRegion}
      filter={filter}
      zoneFilterPath={zoneFilterPath}
      fmcFilterPath={fmcFilterPath}
    />
  );
};

export const generatePMExpressFilterStructure = (
  fmcsWithZoneAndRegion,
  zones,
  initialFilters,
  isGsaInternalUser,
) => {
  const filterStructure = [
    {
      title: 'License plate',
      key: tagNumberFilterPath,
      type: 'typeahead',
      permanent: false,
      value: [],
      operator: '$startsWith',
      position: 'top',
      prefixIcon: '',
      ariaLabel: 'Search by plate number',
      placeholder: 'Search by plate number',
      customFieldProps: {
        inputCharNum: 3,
        debounceDelay: 500,
        promptText: 'Search requires 3 characters',
        showNoResults: false,
        clearPanelFilterOnEmpty: true,
      },
    },

    {
      title: 'VIN',
      key: '$vehicle.serial_number_vin$',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Search by VIN',
      showSearchButton: true,
      minSearchLength: 6,
    },

    {
      title: 'Agency',
      key: 'agency-bureau-boac',
      filters: [
        {
          key: agencyFilterPath,
          title: 'Agency',
          component: PmAgencyFilterItem,
          permanent: false,
          operator: '$exact',
          value: '',
        },
        {
          key: bureauFilterPath,
          title: 'Bureau',
          component: PmBureauFilterItem,
          placeholder: '- Select bureau -',
          noResults: 'No bureaus found',
          permanent: false,
          operator: '$in',
        },
        {
          key: officeFilterPath,
          title: 'Office',
          component: PmOfficeFilterItem,
          placeholder: '- Select office -',
          noResults: 'No office found',
          permanent: false,
          operator: '$in',
        },
        {
          key: agencyIndicatorFilterPath,
          title: 'Agency indicator',
          component: PmAgencyIndicatorFilterItem,
          placeholder: '- Select agency indicator -',
          noResults: 'No agency ind found',
          permanent: false,
          operator: '$in',
        },
      ],
    },

    {
      title: 'POC Email',
      key: primaryPocEmailPath,
      type: 'typeahead',
      permanent: false,
      value: [],
      operator: '$startsWith',
      position: 'top',
      ariaLabel: 'Search by point of contact',
      placeholder: 'Search by point of contact',
      customFieldProps: {
        inputCharNum: 3,
        debounceDelay: 500,
        promptText: 'Search requires 3 characters',
        showNoResults: false,
        clearPanelFilterOnEmpty: true,
      },
    },

    {
      title: 'Customer account',
      key: '$vehicle.customer.legacy_customer_number$',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Legacy customer number',
      showSearchButton: true,
      minSearchLength: 3,
    },
    {
      title: 'Status',
      type: 'multiselect',
      key: pmStatusFilterPath,
      field: pmStatusFilterPath,
      operator: '$in',
      value: [],
      options: [
        {
          value: 'DUE',
          label: 'Due',
          defaultValue: true,
        },
        {
          value: 'OVERDUE',
          label: 'Overdue',
          defaultValue: true,
        },
        {
          value: 'UpToDate',
          label: 'Up to date',
          defaultValue: true,
        },
      ],
      getValue: (filter) => flatten(filter.value.map((v) => v.split(','))),
      hideClear: true,
    },
  ];

  const fmcFilter = {
    title: 'FMC',
    key: 'fmc',
    filters: [
      {
        key: zoneFilterPath,
        permanent: false,
        operator: '$exact',
        value: initialFilters?.zone ? initialFilters?.zone : '',
        type: 'select',
        options:
          zones?.length > 0
            ? [{ value: '', label: '- Select zone - ' }, ...zones]
            : [{ value: '', label: '- Select zone - ' }],
      },
      {
        key: fmcFilterPath,
        placeholder: '- Select FMC  -',
        noResults: 'No fmc found',
        permanent: false,
        operator: '$exact',
        component: ({ filter }) => (
          <PmFmcFilterItem
            filter={filter}
            fmcsWithZoneAndRegion={fmcsWithZoneAndRegion}
          />
        ),
      },
      {
        key: fsrEmailFilterPath,
        permanent: false,
        operator: '$like',
        value: [],
        ariaLabel: 'Search by FSR email',
        placeholder: 'Search by FSR email',
        prefixIcon: '',
        type: 'typeahead',
        id: 'placeholder_FSR',
        customFieldProps: {
          inputCharNum: 6,
          debounceDelay: 500,
          promptText: 'Search requires 6 characters',
          showNoResults: false,
          clearPanelFilterOnEmpty: true,
        },
      },
    ],
  };

  if (isGsaInternalUser()) {
    filterStructure.splice(4, 0, fmcFilter);
  }

  return filterStructure;
};
