/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  AFPTable,
  EmptyState,
  Button,
  Chip,
  Pagination,
  SelectDropdown,
  Spinner,
  TextInput,
} from '@gsa/afp-component-library';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { GET_CUSTOMER_ACCOUNTS_FOR_MODAL } from 'components/customer-accounts/customer-account-gql';
import { createFilter } from 'components/office-management/utils';
import './search-result.css';

export const SelectCustomer = ({ setCustomer, currentFsr }) => {
  const filterOptionMap = {
    boac: {
      label: 'BOAC',
      condition: {
        key: '$boac$',
        operator: '$startsWith',
      },
    },
    customer_account_number: {
      label: 'Leasing account number',
      condition: {
        key: '$customer_account_id$',
        operator: '$startsWith',
      },
    },
    fsr_email: {
      label: 'FSR email',
      condition: {
        key: '$fsr_user_email_address$',
        operator: '$like',
      },
    },

    legacy_customer_number: {
      label: 'Legacy customer number',
      condition: {
        key: '$legacy_customer_number$',
        operator: '$startsWith',
      },
    },
    poc_email: {
      label: 'POC email',
      condition: {
        key: '$primaryContactAssociation.pointOfContact.email_address$',
        operator: '$like',
      },
    },
  };

  const columns = [
    {
      Header: 'Account',
      accessor: 'accountName',
      sortable: true,
      Cell: ({ value }) => value,
    },
    {
      Header: 'POC',
      accessor: 'primaryContactAssociation.pointOfContact.pocEmailAddress',
      sortable: true,
      Cell: ({ value, row }) => {
        return (
          <>
            {row?.original?.primaryContactAssociation?.pointOfContact
              ? value
              : null}
          </>
        );
      },
    },
    {
      Header: 'BOAC',
      accessor: 'boac',
      sortable: true,
      Cell: ({ value }) => value,
    },
    {
      Header: 'FSR',
      accessor: 'fsrUserEmail',
      sortable: true,
      Cell: ({ value }) => <>{value}</>,
    },
    {
      Header: '',
      sortable: false,
      id: 'customer-select-button',
      Cell: ({ row }) => {
        // styling for this button is controlled in search-result.css
        // by overruling table.scss from afp-component-library because
        // table specifies no padding for buttons
        return (
          <Button
            label="Select customer"
            size="small"
            data-testid="customer-select-button"
            onClick={() => {
              setCustomer(row?.original);
            }}
          />
        );
      },
    },
  ];
  const initialPaginationState = {
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  };
  const [filteredCustomersList, setFilteredCustomersList] = useState({});
  const [currentFiltersStatus, setCurrentFiltersState] = useState({
    fsr_email: currentFsr,
  });
  const [currentFilterType, setCurrentFilterType] = useState('default');
  const [currentFilterValue, setCurrentFilterValue] = useState(null);
  const [order, setOrder] = useState('accountName ASC');
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );

  const [getCustomerAccountsList, { loading: customerAccountsListLoading }] =
    useLazyQuery(GET_CUSTOMER_ACCOUNTS_FOR_MODAL, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (responseData) => {
        if (responseData?.getCustomerAccounts) {
          setFilteredCustomersList(responseData.getCustomerAccounts);
        }
      },
    });

  const getData = () => {
    getCustomerAccountsList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters: [
          {
            operator: '$and',
            conditions: [
              ...createFilter(currentFiltersStatus, filterOptionMap),
            ],
          },
        ],
      },
    });
  };

  const onSort = (val) => {
    setOrder(val);
  };

  useEffect(() => {
    getData({
      getCustomerAccountsList,
      paginationState,
      currentFiltersStatus,
      filterOptionMap,
      order,
    });
  }, [paginationState, order, currentFiltersStatus]);

  const getFilterOptions = () =>
    Object.keys(filterOptionMap).map((key) => ({
      value: key,
      label: filterOptionMap[key].label,
    }));
  const filterOptions = [
    { value: 'default', label: '-Select-' },
    ...getFilterOptions(filterOptionMap),
  ];
  return (
    <>
      <h2>Find and select customer</h2>
      <p>
        Use the filters below to find your customer account. Add or remove
        filters as needed to update the table results and find what you need.
      </p>
      <header className="padding-3 bg-blue-5 grid-container maxw-desktop">
        <div className="font-sans-m">Choose from available filters</div>
        <div className="grid-row grid-gap-3 margin-bottom-2">
          <SelectDropdown
            name="Filter dropdown"
            id="filterTypeDropdown"
            data-testId="filterTypeDropdown"
            options={filterOptions}
            containerClassName="grid-col-4"
            onChange={(e) => {
              setCurrentFilterType(e.target.value);
            }}
            value={currentFilterType}
          />
          {currentFilterType !== 'default' && (
            <TextInput
              id="fitlerValue"
              data-testId="filterValue"
              aria-label="Filter value"
              containerClassName="grid-col-5"
              value={currentFilterValue}
              onChange={(e) => setCurrentFilterValue(e.target.value)}
            />
          )}
          <Button
            id="viewAccounts"
            data-testId="viewAccounts"
            label="View accounts"
            variant="outline"
            className="margin-top-4"
            onClick={() => {
              if (currentFilterType !== 'default') {
                setCurrentFiltersState((prevFilters) => {
                  return {
                    ...prevFilters,
                    [currentFilterType]: currentFilterValue,
                  };
                });
                setCurrentFilterValue(null);
                setCurrentFilterType('default');
              }
            }}
          />
        </div>
        <section
          className="padding-2 border border-base-light radius-md bg-white minh-7 display-flex flex-wrap"
          data-testid="filter-chips"
        >
          {Object.keys(currentFiltersStatus).map(
            (filterType) =>
              currentFiltersStatus[filterType] && (
                <section
                  key={filterType}
                  className="margin-right-1 margin-bottom-1"
                >
                  <Chip
                    label={`${
                      _.find(filterOptions, ['value', filterType]).label
                    }:${
                      typeof currentFiltersStatus[filterType] === 'object'
                        ? currentFiltersStatus[filterType].label
                        : currentFiltersStatus[filterType]
                    }`}
                    onClose={() => {
                      setCurrentFiltersState((prevFilters) => {
                        const { [filterType]: remove, ...rest } = prevFilters;
                        return rest;
                      });
                    }}
                  />
                </section>
              ),
          )}
        </section>
      </header>
      {Object.values(currentFiltersStatus).filter((x) => x).length > 0 && (
        <>
          <div className="display-flex flex-justify margin-top-2">
            <div className="text-primary">Results</div>
          </div>
          <AFPTable
            data={
              !customerAccountsListLoading
                ? filteredCustomersList?.rows || []
                : []
            }
            columns={columns}
            selectable={false}
            onSort={onSort}
            fullWidth
          />

          {customerAccountsListLoading && <Spinner className="padding-y-9" />}
          {filteredCustomersList?.rows?.length > 0 && (
            <Pagination
              fullWidth
              variant="advanced"
              itemsCount={filteredCustomersList.count}
              itemsPerPage={paginationState.limit}
              currentPage={paginationState.currentPage}
              onPageChange={(currentPage, itemsPerPage) => {
                const offset = (currentPage - 1) * itemsPerPage;
                setPaginationState({
                  limit: itemsPerPage,
                  offset,
                  currentPage,
                });
              }}
              isReset={paginationState.isReset}
            />
          )}

          {(!filteredCustomersList ||
            filteredCustomersList?.rows?.length === 0) &&
            !customerAccountsListLoading && (
              <div className="bg-gray-3 padding-y-5">
                <div className="text-center padding-y-4">
                  <EmptyState
                    alt="Image not available"
                    hasBackground
                    bottomText={
                      <div className="text-center text-bold">
                        No records found
                      </div>
                    }
                  />
                </div>
              </div>
            )}
        </>
      )}
    </>
  );
};
