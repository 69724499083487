export const tagStatuses = {
  ACKO: {
    status: 'Vendor Acknowledged Order',
    statusShort: 'Acknowledged',
    color: 'Info-Gray',
  }, // color to be used for tag background
  ATTD: {
    status: 'Tag Attached to Vehicle',
    statusShort: 'Attached',
    color: 'Ready-Gray',
  },
  DESA: {
    status: 'Tag Destroyed by Agency',
    statusShort: 'Destroyed',
    color: 'Inactive-Gray',
  },
  DESV: {
    status: 'Tag Destroyed by Vendor',
    statusShort: 'Destroyed',
    color: 'Inactive-Gray',
  },
  FLOR: {
    status: 'Fleet Ordered Tag',
    statusShort: 'Ordered',
    color: 'Info-Gray',
  },
  MISS: {
    status: 'Tag Missing, Lost or Stolen',
    statusShort: 'Missing',
    color: 'Urgent-Gray',
  },
  RECV: {
    status: 'Tag Received by Customer',
    statusShort: 'Received',
    color: 'Info-Gray',
  },
  RETN: {
    status: 'Tag Returned to Vendor for Destruction',
    statusShort: 'Pending destruction',
    color: 'Warning-Gray',
  },
  SHIP: {
    status: 'Tag Shipped to Customer',
    statusShort: 'Shipped',
    color: 'Warning-Gray',
  },
  TGCH: {
    status: 'Tag Changed by Agency',
    statusShort: 'Changed',
    color: 'Warning-Gray',
  },
  UNAT: {
    status: 'Tag Unattached from Vehicle',
    statusShort: 'Unattached',
    color: 'Urgent-Gray',
  },
};

export const recallStatuses = {
  Open: {
    status: 'Open',
    color: 'Ready-Gray',
  },
  Closed: {
    status: 'Closed',
    color: 'Inactive-Gray',
  },
  'Closed by Mfg': {
    status: 'Closed',
    color: 'Inactive-Gray',
  },
  'Closed by Customer': {
    status: 'Closed by Customer',
    color: 'Inactive-Gray',
  },
};

export const itemInventoryStatuses = {
  20: {
    status: 'Assigned Vehicle',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  21: {
    status: 'Paid Storage-IFMS (Stored at Agency Location)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  22: {
    status: 'Paid Storage-Agency (Stored at Agency Location)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  25: {
    status: 'Dispatched Vehicle',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  40: {
    status: 'New Vehicle Storage',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  45: {
    status: 'Idle Vehicle-Less than one Month',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  46: {
    status: 'Idle Vehicle-Greater than one Month',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  47: {
    status: 'Regional VAT (less than or equal to 45 days)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  48: {
    status: 'National VAT (greater than 45 days)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  60: {
    status: 'Disposal Storage (Mileage)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  61: {
    status: 'Disposal Storage (Age & Mileage)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  62: {
    status: 'Disposal Storage (Age)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  63: {
    status: 'Disposal Storage (Uneconomical to Repair)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  64: {
    status: 'Disposal Storage (Accident/Totaled)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  65: {
    status: 'Disposal (Survey)',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  66: {
    status: 'Non-Reimbursable Sales Transactions',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  67: {
    status: 'Reserved for Reimbursable Sales Transactions',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  68: {
    // Just added
    status: 'Transferred',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  69: {
    status: 'Transferred to Another Region',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  70: {
    status: 'Disposed of (Sold)',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  71: {
    status: 'Disposed of (Sold)',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  72: {
    status: 'Disposed of (Sold)',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  73: {
    status: 'Disposed of (Sold)',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  74: {
    status: 'Disposed of (Sold)',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  75: {
    status: 'Vehicle Stolen',
    displayStatus: 'Missing/stolen',
    altStatus: 'Missing/Stolen',
    color: 'Urgent-Gray',
  },
  76: {
    status: 'Removed From Inventory (Non-Reimbursable Sales Transaction)',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  77: {
    status: 'Disposed of (Other)',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  79: {
    status:
      'Leased Vehicle (system-generated identifier to Finance for accounting purposes only)',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  81: {
    status: 'Tag Change',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  83: {
    status: 'Sold by the Sales Branch (SASy Interface)',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  85: {
    status: 'Invalid / Not Available',
    displayStatus: 'N/A',
    altStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  AC: {
    status: 'Active',
    displayStatus: 'Active',
    color: 'Ready-Gray',
  },
  ACKO: {
    status: 'Acknowledged',
    displayStatus: 'Acknowledged',
    color: 'Info-Gray',
  },
  DD: {
    status: 'Delivered',
    displayStatus: 'Delivered',
    color: 'Ready-Gray',
  },
  AN: {
    status: 'Available from Vendor',
    displayStatus: 'Ordered',
    color: 'Info-Gray',
  },
  CC: {
    status: 'Shipped',
    displayStatus: 'Ordered',
    color: 'Info-Gray',
  },
  IN: {
    status: 'Order In-Process',
    displayStatus: 'Ordered',
    color: 'Info-Gray',
  },
  SP: {
    status: 'Scheduled for Production',
    displayStatus: 'Ordered',
    color: 'Inactive-Gray',
  },
  MS: {
    status: 'Missing or Stolen',
    displayStatus: 'Missing/Stolen',
    color: 'Urgent-Gray',
  },
  SD: {
    status: 'Disposed',
    displayStatus: 'Disposed',
    color: 'Inactive-Gray',
  },
  VC: {
    status: 'VIN change',
    displayStatus: 'N/A',
    color: 'Inactive-Gray',
  },
  TR: {
    status: 'Transferred',
    displayStatus: 'N/A',
    color: 'Inactive-Gray',
  },
  IV: {
    status: 'Invalid / Not Available',
    displayStatus: 'N/A',
    color: 'Inactive-Gray',
  },
};

export const assetLookupOptions = {
  110: 'LE - Law Enforcement',
  111: 'ER - Emergency Response',
  112: 'OS - Overseas',
  113: 'COV - Covered',
  114: 'OTH - Other',
};

export const VMSSubjects = {
  VEHICLE: 'Vehicle',
  TAG: 'Tag',
  ORDER: 'TagOrder',
  FC_REPLACEMENT: 'FCReplacement',
  MOTOR_POOL: 'Motorpool',
  MOTOR_POOL_RESERVATION: 'MotorpoolReservation',
  MOTOR_POOL_REPORT: 'MotorpoolReport',
  UNICOR_ADMIN: 'UNICOR Admin',
  MODIFICATION: 'VehicleModification',
  CUSTOMER_ACCOUNT: 'CustomerAccount',
  ORGANIZATION_PROFILE: 'OrganizationProfile',
  GFVehicleAdmin: 'GFVehicleAdmin',
  GFVehicleFSR: 'GFVehicleFSR',
  GFVehicleCustomer: 'GFVehicleCustomer',
  GFVehicleBusinessManagement: 'GFVehicleBusinessManagement',
  GFMileage: 'GFMileage',
  BillingHistory: 'GFBillingHistory',
};

const view = 'view';
const viewAO = 'viewAO';
const updateAO = 'updateAO';
const viewGF = 'viewGF';
const updateGF = 'updateGF';
const create = 'create';
const update = 'update';

export const VMSOperations = {
  View: view,
  Create: create,
  Update: update,
  Delete: 'delete',
  Approve: 'approve',
  Review: 'review',
  Manage: 'manage',
  Activate: 'activate',
  Certify: 'certify',
  VIEW_TAG_AO: viewAO,
  VIEW_TAG_GF: viewGF,
  UPDATE_TAG_AO: updateAO,
  UPDATE_TAG_GF: updateGF,
  VIEW_VEHICLE_AO: viewAO,
  VIEW_VEHICLE_GF: viewGF,
  UPDATE_VEHICLE_AO: updateAO,
  UPDATE_VEHICLE_GF: updateGF,
  VIEW_ORDER_AO: viewAO,
  VIEW_ORDER_GF: viewGF,
  UPDATE_ORDER_AO: updateAO,
  UPDATE_ORDER_GF: updateGF,
  CREATE_VEHICLE: create,
  UPDATE_ORDER: update,
  CANCEL: 'cancel',
  SELF_INVITE: 'selfInvite',
};

export const UserType = {
  CUSTOMER: 1,
  VENDOR: 2,
  GSA_EMPLOYEE: 3,
  PUBLIC: 4,
  SYSTEM_USER: 5,
};

export const usCountryCode = '233';

export const getFeatures = () => window?.AFP_CONFIG?.features || {};

export const DEFAULT_SELECT_OPTION_LABEL = '- Select -';

export const isTest = process.env.NODE_ENV === 'test';

export const STATES_LIST = [
  {
    stateName: 'Armed Forces America',
    stateCode: 'AA',
  },
  {
    stateName: 'Armed Forces Canada, Europe, Middle East, Africa',
    stateCode: 'AE',
  },
  {
    stateName: 'Alaska',
    stateCode: 'AK',
  },
  {
    stateName: 'Alabama',
    stateCode: 'AL',
  },
  {
    stateName: 'Armed Forces Pacific',
    stateCode: 'AP',
  },
  {
    stateName: 'Arkansas',
    stateCode: 'AR',
  },
  {
    stateName: 'America Samoa',
    stateCode: 'AS',
  },
  {
    stateName: 'Arizona',
    stateCode: 'AZ',
  },
  {
    stateName: 'California',
    stateCode: 'CA',
  },
  {
    stateName: 'Colorado',
    stateCode: 'CO',
  },
  {
    stateName: 'Connecticut',
    stateCode: 'CT',
  },
  {
    stateName: 'District of Columbia',
    stateCode: 'DC',
  },
  {
    stateName: 'Delaware',
    stateCode: 'DE',
  },
  {
    stateName: 'Florida',
    stateCode: 'FL',
  },
  {
    stateName: 'Federated States of Micronesia',
    stateCode: 'FM',
  },
  {
    stateName: 'Georgia',
    stateCode: 'GA',
  },
  {
    stateName: 'Germany',
    stateCode: 'GE',
  },
  {
    stateName: 'Guam',
    stateCode: 'GU',
  },
  {
    stateName: 'Hawaii',
    stateCode: 'HI',
  },
  {
    stateName: 'Iowa',
    stateCode: 'IA',
  },
  {
    stateName: 'Idaho',
    stateCode: 'ID',
  },
  {
    stateName: 'Illinois',
    stateCode: 'IL',
  },
  {
    stateName: 'Indiana',
    stateCode: 'IN',
  },
  {
    stateName: 'Italy',
    stateCode: 'IT',
  },
  {
    stateName: 'Kansas',
    stateCode: 'KS',
  },
  {
    stateName: 'Kentucky',
    stateCode: 'KY',
  },
  {
    stateName: 'Louisiana',
    stateCode: 'LA',
  },
  {
    stateName: 'Massachusetts',
    stateCode: 'MA',
  },
  {
    stateName: 'Maryland',
    stateCode: 'MD',
  },
  {
    stateName: 'Maine',
    stateCode: 'ME',
  },
  {
    stateName: 'Marshall Island',
    stateCode: 'MH',
  },
  {
    stateName: 'Michigan',
    stateCode: 'MI',
  },
  {
    stateName: 'Minnesota',
    stateCode: 'MN',
  },
  {
    stateName: 'Missouri',
    stateCode: 'MO',
  },
  {
    stateName: 'Northern Mariana Islands',
    stateCode: 'MP',
  },
  {
    stateName: 'Mississippi',
    stateCode: 'MS',
  },
  {
    stateName: 'Montana',
    stateCode: 'MT',
  },
  {
    stateName: 'North Carolina',
    stateCode: 'NC',
  },
  {
    stateName: 'North Dakota',
    stateCode: 'ND',
  },
  {
    stateName: 'Nebraska',
    stateCode: 'NE',
  },
  {
    stateName: 'New Hampshire',
    stateCode: 'NH',
  },
  {
    stateName: 'New Jersey',
    stateCode: 'NJ',
  },
  {
    stateName: 'New Mexico',
    stateCode: 'NM',
  },
  {
    stateName: 'NU',
    stateCode: 'NU',
  },
  {
    stateName: 'Nevada',
    stateCode: 'NV',
  },
  {
    stateName: 'New York',
    stateCode: 'NY',
  },
  {
    stateName: 'Ohio',
    stateCode: 'OH',
  },
  {
    stateName: 'Oklahoma',
    stateCode: 'OK',
  },
  {
    stateName: 'Oregon',
    stateCode: 'OR',
  },
  {
    stateName: 'Pennsylvania',
    stateCode: 'PA',
  },
  {
    stateName: 'Puerto Rico',
    stateCode: 'PR',
  },
  {
    stateName: 'Palau',
    stateCode: 'PW',
  },
  {
    stateName: 'Rhode Island',
    stateCode: 'RI',
  },
  {
    stateName: 'South Carolina',
    stateCode: 'SC',
  },
  {
    stateName: 'South Dakota',
    stateCode: 'SD',
  },
  {
    stateName: 'Tennessee',
    stateCode: 'TN',
  },
  {
    stateName: 'Texas',
    stateCode: 'TX',
  },
  {
    stateName: 'Utah',
    stateCode: 'UT',
  },
  {
    stateName: 'Virginia',
    stateCode: 'VA',
  },
  {
    stateName: 'Virgin Islands',
    stateCode: 'VI',
  },
  {
    stateName: 'Vermont',
    stateCode: 'VT',
  },
  {
    stateName: 'Washington',
    stateCode: 'WA',
  },
  {
    stateName: 'Wisconsin',
    stateCode: 'WI',
  },
  {
    stateName: 'West Virginia',
    stateCode: 'WV',
  },
  {
    stateName: 'Wyoming',
    stateCode: 'WY',
  },
];

export const actionsMap = {
  CREATE: {
    icon: 'add',
    label: 'Create',
  },
  EDIT: {
    icon: 'edit',
    label: 'Edit',
  },
  DELETE: {
    icon: 'cancel',
    label: 'Delete',
  },
};

export const STATES_OCONUS_TERRITORIES = [
  DEFAULT_SELECT_OPTION_LABEL,
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'FM',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MH',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'PW',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UM',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const REPORT_TYPE = {
  FAST: 1,
  CDD: 2,
  MILEAGE: 3,
  EXPENSE: 4,
  REPAIR: 5,
  FUEL_REPORT: 6,
  EXPENSE_EXPORT: 7,
  INVENTORY: 8,
  VEHICLE_INVENTORY: 9,
  LICENSE_PLATE_INVENTORY: 10,
  MILEAGE_DATA_EXPORT: 11,
  RECALL_DATA_EXPORT: 12,
  RESERVATION_HISTORY_REPORT: 13,
  MOTOR_POOL_UTILIZATION_REPORT: 14,
  AIE_REPORT: 15,
  CUSTOM_INVENTORY_REPORT: 16,
  FLEETCARD_REPLACEMENT_EXPORT: 17,
  PREVENTATIVE_MAINTENANCE_EXPRESS: 18,
};

export const REPORT_DELIVERY_METHOD = {
  EMAIL: 1,
};

export const KEY_CODE = {
  ENTER: 13,
  BACK: 8,
  TAB: 9,
};

export const VEHICLE_TYPE = [
  'Standard Vehicle',
  'Motorcycle',
  'Trailer',
  'Low Speed Electric Vehicle',
  'Other',
];

export const ENTRY_METHOD = {
  STORE: 1,
  MANUAL: 2,
};

// form generator select component need the a default string value to show default option
export const FG_DEFAULT_OPTION_VAL = '_';

// the following options are defined in AFP-129784 and AFP-128272
// refer the values to lifecycle-indicator-enum.ts in vms-api repo
export const UPDATE_VEHICLE_LIFE_CYCLE_OPTIONS = {
  Delivered: {
    401: 'At marshaller',
  },
  Active: {
    602: 'At marshaller',
    604: 'Temporary internal use',
    615: 'Available for transfer',
    619: 'Ready for sale',
    624: 'Special disposal',
    621: 'Accident/Totaled',
    620: 'UER',
  },
};

export const LIFECYCLE_UPDATE_TYPE = {
  GF_DELIVERED: 400,
  GF_DELIVERED_ATMARSHALLER: 401,
  GF_DELIVERY_ISSUE: 500,
  GF_DELIVERY_ISSUE_MISSING_EQUIPMENT: 501,
  GF_DELIVERY_ISSUE_DAMAGED_IN_TRANSIT: 502,
  GF_DELIVERY_ISSUE_WRONG_VEHICLE: 503,
  GF_DELIVERY_ISSUE_RECALL: 504,
  GF_DELIVERY_ISSUE_MISDELIVERED: 505,
  GF_DELIVERY_ISSUE_CLAIM_INITIATED: 506,
  GF_DELIVERY_ISSUE_DAMAGE_WAIVED: 507,
  GF_DELIVERY_ISSUE_CLAIM_IN_PROGRESS: 508,
  GF_DELIVERY_ISSUE_CLAIM_DENIED: 509,
  GF_DELIVERY_ISSUE_AWAITING_TRANSPORT: 510,
  GF_DELIVERY_ISSUE_RETURNED_TO_SUPPLIER: 511,
  GF_DELIVERY_ISSUE_UNDER_REPAIR: 512,
  GF_DELIVERY_ISSUE_RESOLVED: 513,
  GF_DELIVERY_ISSUE_REPLACEMENT_ORDERED: 514,
  GF_DELIVERY_ISSUE_EQUIPMENT_ORDERED: 515,
  GF_ACTIVE: 600,
  GF_ACTIVE_NEW_VEHICLE: 601,
  GF_ACTIVE_AT_MARSHALLER: 602,
  GF_ACTIVE_ASSIGNED: 603,
  GF_ACTIVE_TEMPORARY_INTERNAL_USE: 604,
  GF_ACTIVE_UNASSIGNED: 605,
  GF_ACTIVE_MOTOR_POOL: 606,
  GF_ACTIVE_MISSING_EQUIPMENT: 607,
  GF_ACTIVE_OUT_OF_SERVICE: 608,
  GF_ACTIVE_UNDER_REPAIR: 609,
  GF_ACTIVE_CLAIM_IN_PROGRESS: 610,
  GF_ACTIVE_EQUIPMENT_ORDERED: 611,
  GF_ACTIVE_HOLD_OVER: 612,
  GF_ACTIVE_SEASONAL_USE: 613,
  GF_ACTIVE_IDLE: 614,
  GF_ACTIVE_AVAILABLE_FOR_TRANSFER: 615,
  GF_ACTIVE_STOP_DRIVE_RECALL: 616,
  GF_ACTIVE_LITIGATION_HOLD: 617,
  GF_ACTIVE_REPLACEMENT_ORDERED: 618,
  GF_ACTIVE_READY_FOR_SALE: 619,
  GF_ACTIVE_UER: 620,
  GF_ACTIVE_ACCIDENT_TOTALED: 621,
  GF_ACTIVE_NON_REIMBURSABLE: 622,
  GF_ACTIVE_CR_TRANSFER: 623,
  GF_ACTIVE_SPECIAL_DISPOSAL: 624,
  GF_ACTIVE_IN_TRANSIT: 625,
  GF_ACTIVE_AT_SALE_LOCATION: 626,
  GF_ACTIVE_SALE_PREP: 627,
  GF_ACTIVE_LOTTED_FORSALE: 628,
  GF_ACTIVE_SALE_PENDING: 629,
  GF_MISSING_STOLEN: 700,
  GF_MISSING_STOLEN_ASSIGNED: 701,
  GF_MISSING_STOLEN_CLOSED_OUT: 702,
  GF_MISSING_STOLEN_NEW_VEHICLE: 703,
  GF_MISSING_STOLEN_UNASSIGNED: 704,
  GF_MISSING_STOLEN_TEMPORARY_INTERNAL_USE: 705,
  GF_MISSING_STOLEN_DISPOSED: 706,
  GF_DISPOSED: 800,
  GF_DISPOSED_LEGACY_MANUAL: 801,
  GF_DISPOSED_INVALID_VIN: 810,
  AO_DELIVERY_ISSUE_DELIVERY_ISSUE: 550,
  AO_DISPOSED: 850,
  AO_DISPOSED_INVALID_VIN: 852,
  AO_MISSING_STOLEN: 750,
  AO_ACTIVE: 650,
  AO_DELIVERED: 450,
  GF_DISPOSED_SOLD_TO_PUBLIC: 802,
};

export const purchaseTypeOptions = [
  {
    code: 'PURCHASE',
    description: 'Purchase',
  },
  {
    code: 'NOREIMBIN',
    description: 'Non-Reimbursable',
  },
  {
    code: 'REIMBIN',
    description: 'Reimbursable',
  },
];
