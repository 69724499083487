/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Breadcrumbs,
  PageTitle,
  RequiredFieldIndicator,
  Icon,
} from '@gsa/afp-component-library';
import QueryString from 'query-string';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { InventoryMgmtGuideText } from 'utilities/user-guide';
import useAlertManager from 'utilities/alert-manager';
import { LoadingQueryResult } from 'components/loading-query-result/loading-query-result';
import { USDateStrToUTCStr } from 'utilities/format';
import moment from 'moment';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleBM,
} from 'utilities/authorization';
import AlertConfig from './config/alert-config';
import WizardForm from './components/wizard/WizardForm';
import exportsConfig, { isGFMileageEnabled } from './config/exports-config';
import { ReportOwnershipSelection } from './widgets/report-ownership-selection';
import { TYPE } from './config/helpers';
import { AgencySelectionGroup } from './widgets/fast-report/agency-selection-group';
import { ReportScheduler } from './widgets/report-scheduler';
import { useExport } from './export-provider';

const reportFiltersPlaceholder = {
  label: 'Report filters',
  component: null,
  stateIds: [],
};

const ExportVehicleReports = () => {
  const { mutation, loading, isScheduled, setCanBeScheduled, selectedReport } =
    useExport();
  const ability = useAppAbility();
  const canUploadDLA =
    canUpdateGFVehicleBM(ability) || canUpdateGFVehicleAdmin(ability);

  const history = useHistory();
  const { type } = QueryString.parse(history.location.search);

  const [reportType, setReportType] = useState(exportsConfig.DEFAULT.value);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isReportSelected = reportType !== exportsConfig.DEFAULT.value;

  const mode = type === TYPE.TEMPLATE ? TYPE.TEMPLATE : TYPE.REPORT;

  const reportTypeConfig =
    reportType !== exportsConfig.DEFAULT.value ? exportsConfig[reportType] : {};

  const {
    stepConfig = mode === TYPE.REPORT ? [reportFiltersPlaceholder] : [],
    ...restFormConfig
  } = reportTypeConfig;

  if (reportTypeConfig?.canBeScheduled) {
    setCanBeScheduled(true);
  } else {
    setCanBeScheduled(false);
  }

  const title =
    mode === TYPE.TEMPLATE
      ? 'Export Template for Bulk Updates'
      : 'Vehicle Report';

  const reportStep = [
    {
      label: `${mode === TYPE.TEMPLATE ? 'Template' : 'Report'} selection`,
      component: ReportOwnershipSelection,
      stateIds: ['vehicle_options', 'reportType'],
    },
    {
      label: 'Agency selection',
      component: AgencySelectionGroup,
      stateIds: ['agency', 'bureau', 'office'],
    },
  ];

  const scheduleStep =
    isScheduled === 'yes'
      ? [
          {
            label: 'Schedule report',
            component: ReportScheduler,
            stateIds: [],
          },
        ]
      : [];

  const [Alert, alertUtils] = useAlertManager(true, true, true);

  const handleOnSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      if (isScheduled === 'yes' && selectedReport) {
        // update schedule
        await mutation.triggerUpdateSchedule({
          variables: {
            updateScheduleInput: {
              id: selectedReport.id,
              reportName: data.report_name,
              frequency: data.frequency,
              scheduleStartDate: USDateStrToUTCStr(
                moment().format('MM/DD/YYYY'),
              ),
              scheduleEndDate: data.frequency_end_date,
              recipientsList: data.additional_recipients,
              reportTypeId: reportTypeConfig.reportType,
              reportCriteria: reportTypeConfig.criteriaProcessorHook(data),
            },
          },
        });
      } else if (isScheduled === 'yes') {
        await mutation.triggerReportSchedule({
          variables: {
            reportScheduleInput: {
              reportName: data.report_name,
              frequency: data.frequency,
              scheduleStartDate: USDateStrToUTCStr(
                moment().format('MM/DD/YYYY'),
              ),
              scheduleEndDate: data.frequency_end_date,
              recipientsList: data.additional_recipients,
              reportTypeId: reportTypeConfig.reportType,
              reportCriteria: reportTypeConfig.criteriaProcessorHook(data),
            },
          },
        });
      } else {
        await mutation.triggerReportExport({
          variables: {
            request: {
              deliveryMethod: reportTypeConfig.deliveryMethod,
              reportType: reportTypeConfig.reportType,
              reportCriteria: reportTypeConfig.criteriaProcessorHook(data),
            },
          },
        });
        alertUtils.showSuccessAlert(
          AlertConfig.success.getContent(),
          AlertConfig.success.heading,
        );
      }
      setReportType(exportsConfig.DEFAULT.value);
    } catch (err) {
      if (err.message.match(/^Report size/i))
        alertUtils.showErrorAlert(
          AlertConfig.sizeError.getContent(),
          AlertConfig.sizeError.heading,
        );
      else
        alertUtils.showErrorAlert(
          AlertConfig.systemError.getContent(),
          AlertConfig.systemError.heading,
        );
    } finally {
      setIsSubmitting(false);
    }
  };

  const watchConfig = {
    id: 'reportType',
    notifier: (value) => {
      if (value) {
        setReportType(value);
      }
    },
  };

  useEffect(() => {
    if (isReportSelected) {
      alertUtils.closeAlert();
    }
  }, [isReportSelected]);

  const generateSubmitLabel = () => {
    if (isScheduled === 'yes' && selectedReport) {
      return 'Update scheduled report';
    }
    if (isScheduled === 'yes') {
      return 'Schedule report';
    }
    return 'Export report to email';
  };

  return (
    <article>
      <LoadingQueryResult
        onLoading={loading?.agenciesQueryLoading || isSubmitting}
      >
        <header>
          <Breadcrumbs
            trail={[
              <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
              <Link to="/reports">Vehicle Reports Manager</Link>,
            ]}
            current={title}
          />
          <Alert />

          <PageTitle title={title} />
          <div className="margin-bottom-4 tablet:grid-col-7">
            {mode === 'report' ? (
              <>
                <h2 style={{ fontWeight: 'normal' }}>
                  Use this tool to export vehicle data to your desktop via an
                  email from this system. You can also schedule reports.
                </h2>

                <p>
                  For more information, please see the scheduled report&nbsp;
                  <a
                    href={`${process.env.PUBLIC_URL}/GSAFleet_gov-Reports-User-Guide.pdf`}
                    className="mobile:display-block"
                    data-testid="report-user-guide"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    user guide
                  </a>{' '}
                  and&nbsp;
                  <a
                    href="https://vimeo.com/gsavisualcommunications/review/885699144/1c990efd14"
                    className="mobile:display-block"
                    data-testid="report-user-video"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How to
                  </a>{' '}
                  video.
                </p>
              </>
            ) : (
              <>
                <p>
                  This tool allows you to export vehicle template for bulk
                  updates. Please complete all the required steps to export the
                  template via an email or download a blank template to your
                  desktop. Once you complete populating the template, please{' '}
                  <Link to="/reports/bulk-import">
                    import the template for bulk updates
                  </Link>
                  .
                </p>
                <InventoryMgmtGuideText />
              </>
            )}
            <div>
              Required fields are marked with an asterisk (
              <RequiredFieldIndicator />
              ).
            </div>
          </div>
          {mode === TYPE.TEMPLATE && (
            <p>
              <a
                href={`${process.env.PUBLIC_URL}/expense-upload-template.xlsx`}
                className="usa-button usa-button--unstyled usa-button--medium"
                data-testid="gsa-expense-template-link"
              >
                <span aria-hidden="true" className="margin-right-1">
                  <Icon
                    type="custom"
                    iconName="custom_download"
                    className="text-base usa-icon--size-1"
                  />
                </span>
                Download blank expense template
              </a>{' '}
              <a
                href={`${process.env.PUBLIC_URL}/mileage-upload-template.xlsx`}
                className="usa-button usa-button--unstyled usa-button--medium margin-left-4"
                data-testid="gsa-mileage-agency-owned-template-link"
              >
                <span aria-hidden="true" className="margin-right-1">
                  <Icon
                    type="custom"
                    iconName="custom_download"
                    className="text-base usa-icon--size-1"
                  />
                </span>
                Download mileage template: Agency owned
              </a>
              {isGFMileageEnabled && (
                <a
                  href={`${process.env.PUBLIC_URL}/mileage-upload-leased-template.xlsx`}
                  className="usa-button usa-button--unstyled usa-button--medium margin-left-4"
                  data-testid="gsa-mileage-leased-template-link"
                >
                  <span aria-hidden="true" className="margin-right-1">
                    <Icon
                      type="custom"
                      iconName="custom_download"
                      className="text-base usa-icon--size-1"
                    />
                  </span>
                  Download mileage template: Leased
                </a>
              )}
              {canUploadDLA && (
                <a
                  href={`${process.env.PUBLIC_URL}/tesla-supercharging-data-template.xlsx`}
                  className="usa-button usa-button--unstyled usa-button--medium margin-left-4"
                  data-testid="gsa-mileage-agency-owned-template-link"
                >
                  <span aria-hidden="true" className="margin-right-1">
                    <Icon
                      type="custom"
                      iconName="custom_download"
                      className="text-base usa-icon--size-1"
                    />
                  </span>
                  Tesla - supercharging data template
                </a>
              )}
              {canUploadDLA && (
                <a
                  href={`${process.env.PUBLIC_URL}/dla-fuel-data-template.xlsx`}
                  className="usa-button usa-button--unstyled usa-button--medium margin-left-4"
                  data-testid="gsa-mileage-leased-template-link"
                >
                  <span aria-hidden="true" className="margin-right-1">
                    <Icon
                      type="custom"
                      iconName="custom_download"
                      className="text-base usa-icon--size-1"
                    />
                  </span>
                  Download DLA fuel data template
                </a>
              )}
            </p>
          )}
          <div>
            <hr />
          </div>
        </header>
        <WizardForm
          key={reportType}
          stepConfig={[...reportStep, ...stepConfig, ...scheduleStep]}
          {...restFormConfig}
          submitLabel={generateSubmitLabel()}
          counterSize="large"
          onSubmit={handleOnSubmit}
          watchConfig={watchConfig}
          defaultValues={{ reportType }}
          hideActionButtons={!isReportSelected}
          mode={mode}
          setReportType={setReportType}
        />
        <Link to="/reports">Cancel vehicle report</Link>
      </LoadingQueryResult>
    </article>
  );
};

export default ExportVehicleReports;
