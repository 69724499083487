/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom';
import React from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { toString, split, toNumber, get } from 'lodash';
import { Icon, Popover, AFPTableRowAction } from '@gsa/afp-component-library';
import './exempt-popover.css';
import useUser from './use-user';
import { LIFECYCLE_UPDATE_TYPE } from './consts';

export const UNKNOWN = 'UNKNOWN';

export const formatTagExpDate = (exp) => {
  const stringExp = toString(exp);
  if (stringExp === '0') {
    return '00/0000'; // needed to properly format legacy data, where exp date is 0
  }
  if (stringExp) {
    return `${stringExp.slice(4)}/${stringExp.slice(0, 4)}`;
  }
  return '—';
};

export const tagExpStrToDate = (exp) => {
  if (exp) {
    const formattedTagExpDate = formatTagExpDate(exp);
    const splitExpDate = split(formattedTagExpDate, '/', 2);
    const expYear = toNumber(splitExpDate[1]);
    const expMonth = toNumber(splitExpDate[0]) - 1;
    return new Date(expYear, expMonth);
  }
  return false;
};

export function titleCase(str) {
  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  if (str)
    return str.toLowerCase().split(' ').map(capitalizeFirstLetter).join(' ');
  return '-';
}

export const generateDisassociationMessage = (data) => {
  const { tagNumber, expirationDate, vinToDisassociateFrom, type } = data;
  return (
    <>
      License plate <strong>{tagNumber}</strong> with expiration date{' '}
      <strong>{formatTagExpDate(expirationDate)}</strong>{' '}
      {vinToDisassociateFrom && (
        <>
          <span>attached to VIN </span>
          <Link to={`/vehicles/${encodeURIComponent(vinToDisassociateFrom)}`}>
            {vinToDisassociateFrom}
          </Link>
        </>
      )}{' '}
      has been reported
      <strong>{type === 'lostStolen' ? ' lost or stolen' : ' damaged'}</strong>.
      The status of <strong>Missing</strong> has been applied to both A and B
      plates (if a 2-plate set).
      <br />
      <div className="padding-top-1 text-bold">What to do next:</div>
      <ul>
        {type === 'lostStolen' ? (
          <>
            <li>
              Report the missing license plate to your local security office and
              your local police department (or equivalent)
            </li>
            <li>
              <a href="mailto:CUSGDLP@usdoj.gov">Contact UNICOR</a> immediately
              to obtain a replacement license plate
            </li>
            <li>
              If this plate was part of a pair (A/B), submit the remaining plate
              for destruction
            </li>
          </>
        ) : (
          <>
            <li>
              <a href="mailto:CUSGDLP@usdoj.gov">Contact UNICOR</a> immediately
              to obtain a replacement license plate with
              <strong> same number as damaged license plate.</strong> When
              ordering the replacement plate, be sure and add the letter &quot;R&quot; to
              the end of the license plate number to indicate that this is a
              replacement.
            </li>
            <li>
              If this plate was part of a pair (A/B), submit
              <strong> both plates</strong> for destruction. Otherwise just
              submit the damaged A plate.
            </li>
          </>
        )}
      </ul>
    </>
  );
};

export const generateReconciliationMessage = (data) => {
  const tagNumber = get(data, [0, 'tagNumber'], get(data, [0, 'id']));
  return (
    <>
      The status of license plate <strong>{tagNumber}</strong> has been updated
      to <strong>received</strong>.
    </>
  );
};

export const shippedWithoutUnicor = (status, orderNumber) =>
  status === 'SHIP' && !orderNumber;

export const canDisassociate = (status, orderNumber) => {
  return (
    status !== 'MISS' &&
    status !== 'RETN' &&
    status !== 'UNAT' &&
    status !== 'DESA' &&
    status !== 'DESV' &&
    !shippedWithoutUnicor(status, orderNumber)
  );
};

export const canSubmitDestruction = (status) => {
  return (
    status === 'RECV' ||
    status === 'ATTD' ||
    status === 'MISS' ||
    status === 'ACKO' ||
    status === 'UNAT'
  );
};

export const canReconcile = (id, status, createdBy, orderNumber) => {
  if (
    !id ||
    id.substr(0, 1) === 'G' ||
    shippedWithoutUnicor(status, orderNumber)
  )
    return false;
  // only tags in status 'SHIP'/'SH'/'RETN/DESV' and system MISS are eligible for reconcile
  const validStatusToReconcile = ['SHIP', 'SH', 'RETN', 'DESV'];
  return (
    validStatusToReconcile.includes(status) ||
    (status === 'MISS' && createdBy === 'system')
  );
};

export const canBulkReconcile = (id, status, createdBy) => {
  const { isRole } = useUser();
  const isInternalAdmin = isRole('FMVRSAdminRole') || isRole('SiteAdmin');
  const isGTag = id.substr(0, 1) === 'G';

  if (!id || (isGTag && !isInternalAdmin)) return false;

  // customers should reconcile all
  // if (isCustomer() && !areAllTagsNonNull(tags)) return false;

  // only tags in status 'SHIP'/'SH' and MISS (system) are eligible for FMVRS
  const validGFStatusToReconcile = ['SHIP', 'SH'];

  if (isInternalAdmin && isGTag) {
    return (
      validGFStatusToReconcile.includes(status) ||
      (status === 'MISS' && createdBy === 'system')
    );
  }

  // only tags in status 'SHIP'/'SH'/'RETN/DESCV' and system MISS are eligible for reconcile
  const validStatusToReconcile = ['SHIP', 'SH', 'RETN', 'DESV'];
  return (
    validStatusToReconcile.includes(status) ||
    (status === 'MISS' && createdBy === 'system')
  );
};

export const fiscalYear = () => {
  let fy = new Date().getFullYear();
  // check if current date is past Sept 30th
  if (new Date().getMonth() >= 9) {
    fy += 1;
  }
  return fy;
};

export const dateTime = (stringDate) => {
  if (stringDate && typeof stringDate === 'string') {
    return new Date(parseInt(stringDate, 10))
      .toJSON()
      .slice(0, 19)
      .replace('T', ' ');
  }
  return new Date().toJSON().slice(0, 19).replace('T', ' ');
};

export const ExemptPopover = () => (
  <div className="exempt-pop">
    <span className="position-relative popover-info-icon margin-top-1">
      <Icon className="" iconName="info" />
    </span>
    <Popover className="exempt-popover">
      <h4 className="exempt-popover-heading margin-y-1">
        License Plate Exemption
      </h4>
      <p className="margin-y-0">
        The agency has an exemption from the requirement to display official
        U.S. Government license plates for this vehicle under sections{' '}
        <a href="https://www.ecfr.gov/current/title-41/section-102-34.160">
          § 102-34.160
        </a>
        ,{' '}
        <a href="https://www.ecfr.gov/current/title-41/section-102-34.175">
          § 102-34.175
        </a>
          or{' '}
        <a href="https://www.ecfr.gov/current/title-41/section-102-34.180">
           § 102-34.180
        </a>{' '}
        and it must be registered and inspected in accordance with the laws of
        the jurisdiction where the motor vehicle is regularly operated.
      </p>
    </Popover>
  </div>
);

export const nullForForm = (value) => (value === null ? '' : value);

export const formatCurrency = (price) => {
  if (Number.isNaN(price)) {
    return '';
  }
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const hasAbilityTo = (operation, entity) => {
  const ability = useAppAbility();
  return ability.can(operation, entity);
};

export const genRowActions = ({
  actions,
  rowData: { row, ...otherProps },
  onSelectAction,
  skipRoleCheck = null,
  actionFilterFn = null,
}) => {
  const roleCheck = (act) =>
    skipRoleCheck ? true : hasAbilityTo(act.operation, act.entity);

  const rowActions = actions
    ?.map((act) =>
      roleCheck(act) ? { icon: act.icon, label: act.label } : null,
    )
    .filter((act) => act);

  if (rowActions?.length)
    return (
      <div className="float-right align-right">
        <AFPTableRowAction
          actions={actionFilterFn ? actionFilterFn(rowActions) : rowActions}
          onSelectAction={(label) => {
            onSelectAction(label, row.original, row);
          }}
          row={row}
          {...otherProps}
        />
      </div>
    );
  return <div className="float-right align-right">–</div>;
};

export const formatUSDecimal = (price) => {
  if (price === undefined || price === null || Number.isNaN(price)) {
    return '';
  }

  const parsed = parseFloat(price);
  if (Number.isNaN(parsed)) {
    return '';
  }

  return parsed.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
  });
};

export const isArrayOfConstValShallowEqual = (arr1, arr2) => {
  if (arr1 === arr2) {
    return true;
  }
  if (Array.isArray(arr1) && Array.isArray(arr2)) {
    return arr1?.sort().toString() === arr2?.sort().toString();
  }
  return undefined;
};

const STATE_TAG_EXPIRATION_YEAR_MIN = 1970;

export const formatStatePlateExp = (statePlateExp) => {
  if (!statePlateExp) {
    return null;
  }
  const statePlateExpString = statePlateExp.toString();
  const year = statePlateExpString.substring(0, 4);
  const month = statePlateExpString.substring(4, 6);
  // Adding delimiter between month and year based on month length
  const delimiter = '/';
  const formattedDate = [month, delimiter, year].join('');
  return statePlateExpString.length > 0 ? formattedDate : '';
};

export const validateStatePlateExp = (statePlateExp) => {
  if (!statePlateExp || statePlateExp.length === 0) {
    return {};
  }

  const month = parseInt(statePlateExp.substring(0, 2), 10);
  const year = statePlateExp.substring(3, 7);
  const regExp = /[0-9]{2}[/][0-9]{4}$/;

  if (!regExp.test(statePlateExp)) {
    return {
      message:
        'Invalid state license plate expiration date. Required format is MM/YYYY',
    };
  }

  if (
    regExp.test(statePlateExp) &&
    (month > 12 ||
      month === 0 ||
      Number.isNaN(month) ||
      year.length > 4 ||
      parseInt(year, 10) < STATE_TAG_EXPIRATION_YEAR_MIN ||
      Number.isNaN(parseInt(year, 10)))
  ) {
    return {
      message:
        'Invalid state license plate expiration Date. Please enter a valid date.',
    };
  }

  return {};
};

export const getAssignmentDateFromLifecycle = (assetLifecycle) => {
  if (!assetLifecycle || assetLifecycle.length === 0) {
    return null;
  }

  const lifecycleIndicator = assetLifecycle.find(
    (l) =>
      l?.lifeCycle?.lifecycleIndicatorId ===
        LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_ASSIGNED.toString() ||
      l?.lifeCycle?.lifecycleIndicatorId ===
        LIFECYCLE_UPDATE_TYPE.GF_MISSING_STOLEN_ASSIGNED.toString(),
  );
  return lifecycleIndicator?.lifecycleIndicatorBeginDate;
};

export const extractMonthYear = (statePlateExp) => {
  if (!statePlateExp || statePlateExp.length === 0) {
    return null;
  }
  const month = statePlateExp.substring(0, 2);
  const year = statePlateExp.substring(3, 7);
  return parseInt([year, month].join(''), 10);
};
