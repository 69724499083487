import { useMutation } from '@apollo/client';
import { Button, DetailsTable, Spinner } from '@gsa/afp-component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { getCodeAndNameByOwnerShip } from 'utilities/format';
import { UPDATE_ALD_CUSTOMER_ACCOUNT } from 'services/data-layer';
import { VehiclePropType } from 'utilities/types';
import useUser from 'utilities/use-user';
import usePortalModal from 'utilities/portal-modal';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
  canUpdateGFVehicleCustomer,
} from 'utilities/authorization';
import { emdash } from 'components/common';
import { useVehicle } from '../../../vehicle-context-provider';
import SectionBlock from './section-block';
import {
  getOfficeDetails,
  vehicleOwnership,
} from '../helpers/vehicle-details-helper';
import CustomerEdit from './customer-edit';

const GFCustomerInfo = ({ data }) => {
  const ability = useAppAbility();
  const { isGsaEmployee } = useUser();
  const history = useHistory();
  const canEdit =
    isFeatureEnabled('edit-gf-vehicle') &&
    (canUpdateGFVehicleAdmin(ability) ||
      canUpdateGFVehicleFSR(ability) ||
      canUpdateGFVehicleCustomer(ability));

  const [CustomerInformationModal, openModal, closeModal] = usePortalModal();
  const { vehicle, setSectionMsg, refetchVehicle } = useVehicle();

  const [
    updateAldAccountInformation,
    { loading: updatingAldAccountInformation },
  ] = useMutation(UPDATE_ALD_CUSTOMER_ACCOUNT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      refetchVehicle();
      closeModal();
      setSectionMsg({
        type: 'success',
        message: 'Vehicle was successfully updated.',
      });
    },
    onError: (err) => {
      setSectionMsg({
        type: 'error',
        message: `Error occurred updating customer information: ${err.message}`,
      });
      closeModal();
    },
  });

  const {
    agency,
    ownershipTypeCode,
    customer,
    agencyCode,
    bureau,
    bureauCode,
  } = data;

  const handleUpdateAldAccountInformation = (fundCode, account1, account2) => {
    updateAldAccountInformation({
      variables: {
        updateAldInput: {
          assetId: vehicle.uuid,
          fundCode,
          account1,
          account2,
        },
      },
    });
  };
  const agencyCodeName = getCodeAndNameByOwnerShip({
    ownershipTypeCode,
    code: agencyCode,
    name: agency?.name,
    customer,
  });

  const bureauCodeName = getCodeAndNameByOwnerShip({
    ownershipTypeCode,
    code: bureauCode,
    name: bureau?.name,
    customer,
    isAgency: false,
  });
  let officeCodeName = getOfficeDetails(data.office, data.officeCode);
  const customerPhysicalOffice = data?.customer?.customerPhysicalOffice;
  if (customerPhysicalOffice) {
    officeCodeName = customerPhysicalOffice.officeName
      ? `${customerPhysicalOffice.officeCode} - ${customerPhysicalOffice?.officeName}`
      : '';
  }
  const boac = vehicle?.customer?.boac;
  const isGSAAssigned = boac === '777777' || boac === '999999';
  const isInternalCustomerAccount =
    vehicle?.customer?.legacyCustomerNumber === '9904709';
  const hideCustomerInfo = isGSAAssigned && isInternalCustomerAccount;
  const internalData = [
    ['Ownership', vehicleOwnership(data.ownershipTypeCode) || emdash],
    ['Agency', agencyCodeName],
    ['Bureau', bureauCodeName],
    ['Office', officeCodeName],
    [
      'Legacy customer number',
      !hideCustomerInfo
        ? vehicle?.customer?.legacyCustomerNumber
        : emdash,
    ],
    [
      'Account name',
      !hideCustomerInfo ? (
        <Button
          variant="unstyled"
          label={vehicle?.customer?.accountName}
          onClick={() => {
            history.push(
              `/customer-accounts/view/${encodeURIComponent(
                vehicle?.customer?.customerId,
              )}`,
            );
          }}
        />
      ) : (
        emdash
      ),
    ],
    ['Account number', !hideCustomerInfo ? vehicle?.customer?.customerId : emdash],
    ['BOAC', !hideCustomerInfo ? vehicle?.customer?.boac : emdash],
    ['Zone', vehicle?.fleetManagementCenter?.zoneId],
    ['FMC', vehicle?.fleetManagementCenter?.name],
    ['Fund code', vehicle?.ald?.fundCode],
    ['Description 1', vehicle?.ald?.account1],
    ['Description 2', vehicle?.ald?.account2],
  ];

  const externalData = [
    ['Ownership', vehicleOwnership(data.ownershipTypeCode) || emdash],
    ['Agency', agencyCodeName],
    ['Bureau', bureauCodeName],
    ['Office', officeCodeName],
    [
      'Legacy customer number',
      !hideCustomerInfo ? vehicle?.customer?.legacyCustomerNumber : emdash,
    ],
    [
      'Account name',
      !hideCustomerInfo ? (
        <Button
          variant="unstyled"
          label={vehicle?.customer?.accountName}
          onClick={() => {
            history.push(
              `/customer-accounts/view/${encodeURIComponent(
                vehicle?.customer?.customerId,
              )}`,
            );
          }}
        />
      ) : (
        emdash
      ),
    ],
    ['Account number', !hideCustomerInfo ? vehicle?.customer?.customerId : emdash],
    ['BOAC', !hideCustomerInfo ? vehicle?.customer?.boac : emdash],
    ['Fund code', vehicle?.ald?.fundCode],
    ['Description 1', vehicle?.ald?.account1],
    ['Description 2', vehicle?.ald?.account2],
  ];

  const detailTableData = isGsaEmployee() ? internalData : externalData;
  return (
    <>
      <SectionBlock title="Customer">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={detailTableData}
        />
        {canEdit && (
          <Button
            label="Edit"
            data-testid="agency-edit-button"
            onClick={() => {
              openModal();
            }}
            variant="outline"
            className="bg-white margin-top-2"
            aria-label="edit agency"
          />
        )}

        <CustomerEdit
          agencyCodeName={agencyCodeName}
          bureauCodeName={bureauCodeName}
          officeCodeName={officeCodeName}
          vehicle={vehicle}
          CustomerInformationModal={CustomerInformationModal}
          onClose={() => {
            closeModal();
          }}
          isInternal={isGsaEmployee()}
          onSave={handleUpdateAldAccountInformation}
          hideCustomerInfo={hideCustomerInfo}
        />
      </SectionBlock>
      {updatingAldAccountInformation && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

GFCustomerInfo.propTypes = {
  data: PropTypes.shape(VehiclePropType).isRequired,
};

export default GFCustomerInfo;
