import { gql } from '@apollo/client';

export const IMPORT_GF_BULK_MILEAGE = gql`
  mutation ImportGfBulkMileage($importInput: [BulkGfMileageInput!]!) {
    importGfBulkMileage(importInput: $importInput) {
      ... on BulkUpdateResponse {
        tagNumber
        vin
      }
      ... on BulkUpdateError {
        errorResponse {
          validationErrors {
            errors {
              code
              message
              fields
            }
          }
          vin
          tagNumber
        }
      }
    }
  }
`;

export const IMPORT_GF_BULK_MILEAGE_TXT = gql`
  mutation ImportGfBulkMileageTxt($importInput: [BulkGfMileageTxtInput!]!) {
    importGfBulkMileageTxt(importInput: $importInput) {
      ... on BulkUpdateResponse {
        tagNumber
        vin
      }
      ... on BulkUpdateError {
        errorResponse {
          validationErrors {
            errors {
              code
              message
              fields
            }
          }
          vin
          tagNumber
        }
      }
    }
  }
`;

export const IMPORT_BULK_MILEAGE = gql`
  mutation ImportBulkMileage($importInput: [BulkMileageInput!]!) {
    importBulkMileage(importInput: $importInput) {
      ... on BulkUpdateResponse {
        vin
      }
      ... on BulkUpdateError {
        errorResponse {
          validationErrors {
            errors {
              code
              message
              fields
            }
          }
          vin
        }
      }
    }
  }
`;

export const IMPORT_BULK_EXPENSE = gql`
  mutation ImportBulkExpense($importInput: [BulkExpenseInput!]!) {
    importBulkExpense(importInput: $importInput) {
      ... on BulkUpdateResponse {
        vin
      }
      ... on BulkUpdateError {
        errorResponse {
          validationErrors {
            errors {
              code
              message
              fields
            }
          }
          vin
        }
      }
    }
  }
`;

export const IMPORT_DLA_BULK_EXPENSE = gql`
  mutation ImportDlaBulkExpense($importInput: [BulkDlaExpenseInput!]!) {
    importDlaBulkExpense(importInput: $importInput) {
      ... on BulkUpdateResponse {
        tagNumber
      }
      ... on BulkUpdateError {
        errorResponse {
          validationErrors {
            errors {
              code
              message
              fields
            }
          }
          tagNumber
        }
      }
    }
  }
`;

export const IMPORT_TESLA_SUPERCHARGER_TRANS = gql`
  mutation ImportTeslaSuperchargerTrans(
    $importInput: [BulkTeslaTransactionInput!]!
  ) {
    importTeslaTransactions(importInput: $importInput) {
      ... on BulkUpdateError {
        errorResponse {
          validationErrors {
            errors {
              message
            }
          }
          vin
        }
      }
      ... on BulkUpdateResponse {
        vin
      }
    }
  }
`;

export const PROCESS_BULK_ALD = gql`
  mutation BulkValidateAld($importInput: [AldInput!]!) {
    bulkValidateAld(importInput: $importInput) {
      ... on BulkUpdateResponse {
        vin
      }
      ... on BulkUpdateError {
        errorResponse {
          validationErrors {
            errors {
              code
              message
              fields
            }
          }
          vin
        }
      }
    }
  }
`;
